import React, {FC} from 'react';
import {graphql, Link} from 'gatsby';
import {GatsbyImageProps} from 'gatsby-image';
import Layout from '../components/Layout';
import Features from '../components/Features';
import Testimonials from '../components/Testimonials';
import {People} from '../components/People';
import Contact from '../components/contact/Contact';
import ClientsGrid, {Client} from '../components/Clients';
import {ImageSharp} from '../common/types';
import BlogRoll from '../components/BlogRoll';

type IndexPageTemplateProps = {
	image?: ImageSharp | string
	title: string
	heading: string
	subheading: string
	desc: string;
	mainpitch: {
		title: string
		description: string
	}
	description: string
	intro: {
		blurbs: Array<{image?: ImageSharp | string, title: string, text: string }>
	},
	video: string
	videoPoster: string
	videoTitle: string
	testimonials: Array<{author: string, comapny: string, quote: string}>
	people: Array<{name: string, linkedin: string}>
  clientsIntro: string
	clients: Client[]
}

export const IndexPageTemplate: FC<IndexPageTemplateProps> = ({
  image,
  title,
  heading,
  subheading,
	mainpitch,
	desc,
  description,
  intro,
  video,
  videoPoster,
  videoTitle,
  testimonials,
  people,
  clientsIntro,
  clients
}) => (
	<>
		<div
			className="full-width-image margin-top-0"
			style={{
				backgroundImage: `url(${
					image && !!image.childImageSharp ? image.childImageSharp.fluid.src : image
					})`,
				backgroundPosition: `top left`,
				backgroundAttachment: `fixed`,
			}}
		>
			<div
				style={{
					display: 'flex',
					height: '150px',
					lineHeight: '1',
					justifyContent: 'space-around',
					alignItems: 'left',
					flexDirection: 'column',
				}}
			>
				<h1 className="headline is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
					{title}
				</h1>
				<p className="subheadline is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
					{subheading}
				</p>
			</div>
		</div>
		<section className="section section--gradient">
			<div className="container">
				<div className="section">
					<div className="columns">
						<div className="column is-10 is-offset-1">
							<div className="content">
                <a className="anchor" id='introduction'></a>
                <h3 className="intro-heading has-text-weight-semibold is-size-2">Introduction</h3>
                <p className='someText'>{description}</p>
								<a class="anchor" id='why'></a>
								<h3 className="has-text-weight-semibold is-size-2">Why Vega?</h3>
								<p className='someText'>{desc}</p>
								<a class="anchor" id='how'></a>
								<h3 className="has-text-weight-semibold is-size-2">How We Help</h3>
								<Features gridItems={intro.blurbs}/>
                <a className="anchor" id='about'></a>
                <h3 className="has-text-weight-semibold is-size-2">About Us</h3>
                <p className='someText'>{mainpitch.description}</p>
                <People people={people}/>
								<a class="anchor" id='projects'></a>
								<h3 className="has-text-weight-semibold is-size-2">Previous Projects</h3>
                <p className='someText'>{clientsIntro}</p>
								<ClientsGrid clients={clients}/>
								<a class="anchor" id='testimonials'></a>
								<h3 className="has-text-weight-semibold is-size-2">Consultant Testimonials</h3>
								<Testimonials testimonials={testimonials} />
								<a class="anchor" id='contact'></a>
								<h3 className="has-text-weight-semibold is-size-2">Contact Us</h3>
								<div className="columns contact">
									<div className="column">
										<Contact />
									</div>
									<div className="column card">
										<div className="card-image">
											<iframe
												title="Office Location Map"
												src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.7295918490736!2d-0.09272988422963986!3d51.51817677963697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761cabae509b39%3A0x95ca5518bf8f075e!2sMoor+Place%2C+1+Fore+St+Ave%2C+London+EC2Y+5EJ!5e0!3m2!1sen!2suk!4v1561415544747!5m2!1sen!2suk"
												frameBorder="0"
												allowFullScreen>
											</iframe>
										</div>
										<div className="card-content">
											<div className="media">
												<div className="media-content">
													<p className="title is-4">Vega Financial Technology</p>
													<p className="subtitle is-6">1 Fore Street Avenue, London, EC2Y 9DT</p>
												</div>
											</div>
											<div className="content">
												<a className="email-link" href="mailto:info@vegaft.com">info@vegaft.com</a>
												<a className="phone-link" href="tel:+447963262537">+44 79 6326 2537</a>
											</div>
										</div>
									</div>
								</div>
                <h3 className="has-text-weight-semibold is-size-2">Latest Stories</h3>
                <BlogRoll/>
                <div className="column is-12">
                  <Link className="btn button is-link" to="/blog">
                    Read More
                  </Link>
                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>
);

type IndexPageProps = {
  data: {
    markdownRemark: {
      frontmatter: IndexPageTemplateProps
    }
  }
}

const IndexPage: FC<IndexPageProps> = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	return (
		<Layout>
			<IndexPageTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				video={frontmatter.video}
				videoTitle={frontmatter.videoTitle}
				desc={frontmatter.desc}
				videoPoster={frontmatter.videoPoster}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				intro={frontmatter.intro}
				testimonials={frontmatter.testimonials}
				people={frontmatter.people}
        clientsIntro={frontmatter.clientsIntro}
				clients={frontmatter.clients}
			/>
		</Layout>
	)
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
          extension
        }
        heading
        subheading
        video
        desc
        videoPoster
        videoTitle
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
              extension
            }
            text
            title
          }
          heading
          description
        }
        testimonials {
          author
          company
          quote
        }
        people {
          name
			    linkedin
		    }
		    clientsIntro
        clients {
          image {
            publicURL
            extension
          }
          alt
        }
      }
    }
  }
`;
