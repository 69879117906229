import React, {FC} from 'react';
import linkedinIcon from '../img/linkedin-icon.svg';

export type Person = {
  name: string;
  linkedin?: string;
}

type Props = {
	people: Person[];
}

export const People: FC<Props> = ({people}) => (
	<div className='linkedInContainer'>
    {people.map(({name, linkedin}) => (
      <a key={name} title={`${name}'s Linkedin Profile`} className="linkedin-link" href={`https://uk.linkedin.com/in/${linkedin}`}>
        <img src={linkedinIcon} />
        {name}
      </a>
    ))}
	</div>
);
