import React, {FC} from 'react';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import {GatsbyImageProps} from 'gatsby-image';
import {ImageSharp} from '../common/types';

export type Client = {
	image?: ImageSharp | string
	alt: string
}

type ClientsGridProps = {
	clients: Client[]
}

const ClientsGrid: FC<ClientsGridProps> = ({clients}) => (
  <div className="clients">
    {clients.map((client) => (
      <PreviewCompatibleImage key={client.alt} imageInfo={client}/>
    ))}
  </div>
);

export default ClientsGrid;
