import React, {FC, ReactNode, useState} from 'react';
import {navigate} from 'gatsby-link'

type Props = {}

function encode(data: any) {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&')
}

/**
 * TODO: Lift logic up
 */
const Contact: FC<Props> = () => {
	const [isValidated, setIsValidated] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [bot, setBot] = useState('');
	const onNameChange = (e) => {
		setName(e.target.value);
	};
	const onEmailChange = (e) => {
		setEmail(e.target.value);
	};
	const onMessageChange = (e) => {
		setMessage(e.target.value);
	};
	const onBotChange = (e) => {
		setBot(e.target.value);
	};
	const onSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: encode({
				'form-name': form.getAttribute('name'),
				name,
				email,
				message,
				['bot-field']: bot
			}),
		})
			.then(() => navigate(form.getAttribute('action')))
			.catch(error => alert(error))
	};
	return (
		<form
			name="contact"
			method="post"
			action="/contact/thanks/"
			data-netlify="true"
			data-netlify-honeypot="bot-field"
			onSubmit={onSubmit}
		>
			{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
			<input type="hidden" name="form-name" value="contact"/>
			<div hidden>
				<label>
					Don’t fill this out:{' '}
					<input name="bot-field" onChange={onBotChange}/>
				</label>
			</div>
			<div className="field">
				<label className="label" htmlFor={'name'}>
					Your name
				</label>
				<div className="control">
					<input
						className="input"
						type={'text'}
						name={'name'}
						onChange={onNameChange}
						id={'name'}
						required={true}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label" htmlFor={'email'}>
					Email
				</label>
				<div className="control">
					<input
						className="input"
						type={'email'}
						name={'email'}
						onChange={onEmailChange}
						id={'email'}
						required={true}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label" htmlFor={'message'}>
					Message
				</label>
				<div className="control">
                    <textarea
						className="textarea"
						name={'message'}
						onChange={onMessageChange}
						id={'message'}
						required={true}
					/>
				</div>
			</div>
			<div className="field">
				<button className="button is-link" type="submit">
					Send
				</button>
			</div>
		</form>
	);
};

export default Contact;
