import React, {FC} from 'react';
import {v4} from 'uuid';

export type Testimonial = {
	quote: string;
	company?: string;
	author: string;
}

type Props = {
	testimonials: Testimonial[];
}

const Testimonials: FC<Props> = ({testimonials}) => (
	<>
		{testimonials.map(testimonial => (
			<article key={v4()} className="message">
				<div className="message-body">
					{testimonial.quote}
					<br/>
					<cite> – {testimonial.author}{testimonial.company ? `, ${testimonial.company}` : ''}</cite>
				</div>
			</article>
		))}
	</>
);

export default Testimonials;
